import useScript from '../ui/hooks/useScript';

export function LipscoreScript({ apiKey }) {
  if (typeof window !== 'undefined') {
    window.lipscoreInit = function() {
      window.lipscore.init({
        apiKey: apiKey
      });
    };
  }
  useScript('//static.lipscore.com/assets/se/lipscore-v1.js');

  return null;
}
